const Terminos = () => {
    return (
        <body>
            <h1>TÉRMINOS Y CONDICIONES</h1>

            <ol>
                <li><strong>Fecha de última actualización:</strong> 15 de enero de 2025</li>
                <li><strong>Todos nuestros precios</strong> son expresados en pesos mexicanos.</li>
                <li>Las imágenes presentadas en nuestro sitio web oficial son representaciones de los productos reales, algunas de ellas fueron editadas, dramatizadas y/o hechas con inteligencia artificial. La Fuderia se reserva el derecho de cualquier reclamo, devolución y queja por parte del cliente al recibir su paquete.</li>
                <li>Una vez recibido el pedido, se entiende conformidad del cliente sobre las condiciones del mismo, por lo que no se aceptan cambios ni devoluciones derivadas de perecimiento de productos.</li>
                <li>En caso de que el cliente no se encuentre en el domicilio provisto, y no haya podido ser contactado para recolección del mismo, el costo de envío será retenido, incluso para la promoción de “envíos gratis a partir de $599.00”.</li>
                <li>Todos los pagos se realizan a través de la pasarela de pagos de Openpay y su información de pago se procesará de forma segura.</li>
                <li>El cliente se compromete a proporcionar información precisa y actualizada durante el proceso de compra.</li>
                <li>La Fudería se reserva el derecho de rechazar o cancelar cualquier pedido por cualquier motivo, incluyendo, pero no limitado, a la disponibilidad de productos, errores en la descripción del producto o errores en la información expresada en el sitio web oficial y/o en el resumen de compra.</li>
                <li>Su pedido será entregado de acuerdo al plazo expresado en el apartado de Preguntas Frecuentes en nuestra página.</li>
                <li>La Fudería no es responsable de cualquier reacción física por parte del cliente al consumir sus productos, incluyendo pero no limitado a, reacciones alérgicas, intoxicaciones, entre otras.</li>
                <li>Los productos suministrados por La Fudería se encuentran congelados durante su corto almacenamiento, y el descongelamiento de los mismos no es responsabilidad de La Fudería.</li>
                <li>La información personal proporcionada durante el proceso de compra se trata de acuerdo con nuestra Aviso de Privacidad que puede consultar en nuestro sitio web oficial.</li>
                <li>Para cualquier consulta o asistencia, puede ponerse en contacto con La Fudería a través de <a>preguuuntame@lafuderia.com</a> o vía WhatsApp al número <a>5611120508</a>.</li>
                <li>Nos reservamos el derecho de actualizar o modificar estos Términos y Condiciones en cualquier momento sin previo aviso. Los cambios entrarán en vigencia a partir de su publicación en el sitio web.</li>
                <li>Al realizar una compra en el sitio web de La Fudería, usted (el cliente) reconoce haber leído, entendido y aceptado estos Términos y Condiciones.</li>
            </ol>
        </body>
    )
}
export default Terminos;