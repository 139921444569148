import { Button } from 'primereact/button';
import ProductsView from '../../../public/shopContainer/components/ProductsView';
import '../css/SectionThree.css';
import { useNavigate } from 'react-router-dom';

const SectionThree = ({ reference }) => {
    //    const [products, setProducts] = useState([]);
    const navigate = useNavigate();


    /*useEffect(() => {
        const productos = [ // Dummy registros de productos
            { id: 1, name: 'SALMÓN AHUMADO', peso: '200gr', precio: 500, stock: 'INSTOCK', image: './carrusel/Img01.png' },
            { id: 2, name: 'PULPO', peso: '200gr', precio: 700, stock: 'LOWSTOCK', image: './carrusel/Img02.png' },
            { id: 3, name: 'CAMARÓN', peso: '200gr', precio: 900, stock: 'INSTOCK', image: './carrusel/Img03.png' },
            { id: 4, name: 'ATÚN FRESCO', peso: '200gr', precio: 900, stock: 'OUTOFSTOCK', image: './carrusel/Img03.png' },
            { id: 5, name: 'OTRO', peso: '200gr', precio: 900, stock: 'OUTOFSTOCK', image: './carrusel/Img03.png' },
            { id: 6, name: 'OTRO 2', peso: '200gr', precio: 900, stock: 'OUTOFSTOCK', image: './carrusel/Img03.png' },
            { id: 7, name: 'OTRO 3', peso: '200gr', precio: 900, stock: 'OUTOFSTOCK', image: './carrusel/Img03.png' },
        ];

        setProducts(productos);
    }, []);*/

    /*
     <img src={image1} alt={product.name} className="w-6 shadow-2" style={{height: '250px', borderRadius:'10%'}}/> 
    const productTemplate = (product) => {
        return (
            <div className="border-1 surface-border border-round m-1 text-center" style={{ height: '99%' }}>
                
                <img src='https://cdn.pixabay.com/photo/2017/08/11/14/36/fish-2631412_640.png' alt={product.name} className="w-8 shadow-2" />
                <div>
                    <h1 className="proTitle">{product.name}</h1>
                    <h4 className="mt-0 mb-1">{product.peso}</h4>
                    <h1 className="mt-3 mb-1">${product.precio}</h1>
                    <div className="mt-4 flex flex-wrap justify-content-center">
                        <Button className="flex m-2" icon="pi pi-shopping-cart" rounded style={{ background: '#4a4c5b' }} />
                        <Button className="flex m-2" icon="pi pi-heart-fill" rounded style={{ background: '#4a4c5b' }} />
                        <Button className="flex m-2" icon="pi pi-share-alt" rounded style={{ background: '#4a4c5b' }} />
                    </div>
                </div>
            </div>
        );
    };*/

    return (
        <>

            <div className="SectionThree" ref={reference}>

                <div className="grid mt-8 ">
                    <div className="col-12 text-center">
                        <h1 className="slogan">SE VA Y SE COME CON...</h1>
                    </div>
                </div>
                <div className="grid-productos">
                    <div className="col-12 grid-productos">
                        <ProductsView type={'CARRUSEL'} prom={false} />
                    </div>
                </div>
                <div className="grid mt-8 ">
                    <div className="col-12 text-center">
                        <h1 className="slogan">ARMA TU CAJA DE FRESCURA</h1>
                    </div>
                </div>
                <div className="grid-productos">
                    <div className="col-12 grid-productos">
                        <ProductsView type={'CARRUSEL'} prom={true} />
                    </div>
                </div>

                <div className="grid mt-1">
                    <div className="col-12 text-center">
                        <Button label="VER TODOS" onClick={() => { navigate('/products') }} className="ver-todos-btn" />
                    </div>
                </div>
            </div>

        </>
    );
};

export default SectionThree;
