import React, { useState, useRef, useEffect } from "react";
import useFormPayHook from "../hooks/useFormPayHook";
import { ProgressSpinner } from "primereact/progressspinner";



const ConfirmPay = () => {
    const { functions, states } = useFormPayHook();
    const { completeOpenPay } = functions;
    const [status, setStatus] = useState('unknow');
    const obtenerParametrosUrl = (name) => {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(window.location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    useEffect(() => {
        let id = obtenerParametrosUrl('id');
        orderCharges(id);
    }, [])

    const orderCharges = async (id) => {

        await completeOpenPay(id).then((data) => {
            setStatus(data.chargesStatus);
        }).catch((error) => {
            console.log(error);
        })
    }
    return (
        <>
            <div className="grid mt-2 h-13rem w-full" style={{ overflow: 'hidden' }}>
                <img src={'https://www.shutterstock.com/image-vector/long-banner-set-doodle-seafood-260nw-2188553415.jpg'} alt={'bannerBottom'} className="w-full h-15rem" style={{ objectFit: 'cover' }} />
            </div>
            {
                status === 'completed' ? (
                    <h1 className="text-center">Su pago ha sido procesado exitósamente</h1>
                ) :
                    status === 'unknow' ? (
                        <div className="flex flex-column align-content-center"><h1 className="text-center">Su pago está siendo procesado</h1><ProgressSpinner /></div>
                    ) : (
                        <h1 className="text-center">Su pago no ha sido procesado</h1>
                    )
            }
        </>
    )
}
export default ConfirmPay;