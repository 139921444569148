import fuderiaLogo from '../../../assets/images/LaFudería_Logotipo-04.png';
import './css/contacto.css'
const Contacto = () => {
    return (
        <div className="card-container h-full fadeinup animation-duration-500" style={{}}>
            <div className="flex flex-wrap justify-content-center" style={{ marginTop: '8rem', }}>
                <div className="flex-column" style={{ backgroundColor: 'white', padding: '2rem', borderRadius:'1rem' }}>
                    <label className='tituloContacto'>Contáctanos</label>
                    <p className="subtituloContacto">Número telefónico</p>
                    <p style={{ userSelect: 'none' }} className='contacto'><i className="pi pi-phone" style={{ fontSize: '2rem' }}></i> +52 56 1112 0508</p>
                    <p className="subtituloContacto">Correo electrónico</p>
                    <p style={{ userSelect: 'none' }} className='contacto'><i className="pi pi-envelope" style={{ fontSize: '2rem' }}></i> preguuuntame.contacto@fuderia.com</p>
                    <p className="subtituloContacto">Redes sociales</p>
                    <div className="flex justify-content-evenly">
                        <div className="inline font-bold text-center p-5 border-round"><a style={{ color: '#4b5563' }} href="https://www.facebook.com/profile.php?id=61555553096533"><i className="pi pi-facebook" style={{ fontSize: '3rem', cursor: 'pointer' }}></i></a></div>
                        <div className="inline font-bold text-center p-5 border-round"><a style={{ color: '#4b5563' }} href="https://www.instagram.com/lafuderia/"><i className="pi pi-instagram" style={{ fontSize: '3rem', cursor: 'pointer' }}></i></a></div>
                        <div className="inline font-bold text-center p-5 border-round"><a style={{ color: '#4b5563' }} href="https://x.com/LaFuderia"><i className="pi pi-twitter" style={{ fontSize: '3rem', cursor: 'pointer' }}></i></a></div>
                        <div className="inline font-bold text-center p-5 border-round"><a style={{ color: '#4b5563' }} href="http://www.youtube.com/@LaFuder%C3%ADa"><i className="pi pi-youtube" style={{ fontSize: '3rem', cursor: 'pointer' }}></i></a></div>
                    </div>
                </div>
                <div className="flex-column" style={{ backgroundColor: 'white', padding: '2rem' , borderRadius:'1rem'}}>
                    <img alt='LaFuderia' style={{ width: '50rem' }} src={fuderiaLogo}></img>
                </div>
            </div>
        </div>
    )
}
export default Contacto;