import { useState, useEffect } from "react";
import { useLazyFetch } from "../../../../hooks/common/useFetchV2";

const useFormPayHook = () => {
    const { getDataFetch: codigoPostaal } = useLazyFetch();
    const { getDataFetch: busquedaCliente } = useLazyFetch();
    const { getDataFetch: validateEmail } = useLazyFetch();
    const { getDataFetch: envioDatosPago } = useLazyFetch();
    const { getDataFetch: altaOpenpay } = useLazyFetch();
    const { getDataFetch: cardOpenPay } = useLazyFetch();
    const [direccion, setDireccion] = useState(null);
    const [dataRegsitro, setDataRegsitro] = useState(null);
    const [deviceSessionId, setDeviceSessionId] = useState('');
    const [openpay, setOpenPay] = useState(false);

    const getCodigoPostal = async (cp) => {
        const { data, errorFetch } = await codigoPostaal(`/public/catalogs/find-by-cp/${cp}`, 'GET', {});
        //console.log(data);
        if (data) {
            setDireccion(data);
            return data;
        }
        console.error(errorFetch);
    };

    const getBuscaCorreo = async (correo) => {
        const { data, errorFetch } = await busquedaCliente(`/public/checkout/profile/${correo}`, 'GET', {});

        if (data) {
            setDataRegsitro(data);
            return data;
        }
        console.error(errorFetch);

    };

    const postInsertCart = async (rq) => {
        const { data, errorFetch } = await validateEmail(`/public/insert-cart`, 'POST', { rq });

        if (data) {
            //console.log(data);
            return data;
        }
        console.error(errorFetch);
        return errorFetch;

    };


    const getSearchCard = async (idCustomer) => {
        const { data, errorFetch } = await busquedaCliente(`/OpenPay/card-pay/${idCustomer}`, 'GET', {});

        if (data) {
            //console.log(data);
            return data;
        }
        console.error(errorFetch);
        return errorFetch;
    };


    const postValidateEmail = async (email, code) => {
        //console.log(email, code);
        const rq = {
            "email": email,
            "code": code
        }

        const { data, errorFetch } = await validateEmail(`/public/checkout/profile/validateEmail`, 'POST', { rq });

        if (data) {
            //console.log(data);
            return data;
        }
        console.error(errorFetch);
        //return errorFetch;

    };
    const postClientOpenPay = async (client) => {
        //console.log(email, code);
        const rq = {
            ...client
        }

        const { data, errorFetch } = await altaOpenpay(`/OpenPay/client-openpay`, 'POST', { rq });

        if (data) {
            //console.log("Datos del fetch:", data);
            return data; // Retorna los datos si todo está bien
        } else {
            throw new Error(errorFetch || 'Error desconocido');
        }
    };
    const postCardOpenPay = async (card, token) => {
        //console.log(email, code);
        const rq = {
            ...card
        }

        const { data, errorFetch } = await cardOpenPay(`/OpenPay/card-openpay/${token}`, 'POST', { rq });

        if (data) {
            //console.log("Datos del fetch:", data);
            return data; // Retorna los datos si todo está bien
        } else {
            throw new Error(errorFetch || 'Error desconocido');
        }
    };
    const completeOpenPay = async (token) => {
        //console.log(email, code);
        const { data, errorFetch } = await cardOpenPay(`/OpenPay/orderCharges/${token}`, 'PUT');

        if (data) {
            //console.log("Datos del fetch:", data);
            return data; // Retorna los datos si todo está bien
        } else {
            throw new Error(errorFetch || 'Error desconocido');
        }
    };


    const onSubmit = async (rq) => {
        //console.log(rq);
        // rq = {
        //     ...rq,
        //     "deviceSessionId": deviceSessionId
        // }

        rq = {
            customerId:rq.customerId,
            cardId:rq.idCard,
            amount:rq.totalCarrito,
            description:'Cargo inicial',
            deviceSessionId:deviceSessionId,
            receiverName:`${rq.name} ${rq.apa} ${rq.am}`,
            carrito:rq.carrito,
            address:{
                street:rq.calle,
                referencePoint:rq.referencia,
                neighborhood:rq.referencia,
                externalNumber:rq.numExt,
                internalNumber:rq.numInt,
                zipCode:rq.cp,
            },
            idCustomerOpenPay:rq.idCustomerOpenPay
        }

        try {
            const { data, errorFetch } = await envioDatosPago(`/OpenPay/charges-openpay/${rq.idCustomerOpenPay}`, 'POST', { rq });
            //const { data, errorFetch } = await envioDatosPago(`/OpenPay/data-pay`, 'POST', { rq });
            // const { data, errorFetch } = await busquedaCliente(`/public/checkout/profile/jeje1@mail.com`, 'GET', {});

            if (data) {
                //console.log("Datos del fetch:", data);
                return data; // Retorna los datos si todo está bien
            } else {
                throw new Error(errorFetch || 'Error desconocido');
            }
        } catch (error) {
            console.error("Error en el envío de datos:", error);
            return { error: error.message }; // Retorna el mensaje de error si ocurre una excepción
        }
    };

    useEffect(() => {
        // Función para cargar el script de OpenPay dinámicamente
        const loadOpenPayScript = () => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = 'https://js.openpay.mx/openpay-data.v1.min.js';
                script.async = true;
                script.onload = resolve; // Resolvemos la promesa cuando el script se haya cargado
                script.onerror = reject; // Rechazamos la promesa si hay un error
                document.body.appendChild(script);
            });
        };

        const setupDeviceSessionId = () => {
            if (window.OpenPay) {
                const sessionId = window.OpenPay.deviceData.setup();
                setDeviceSessionId(sessionId);
            } else {
                console.error('OpenPay no está disponible');
            }
        };

        // Cargar el script y luego configurar el deviceSessionId
        loadOpenPayScript().then(() => {
            setupDeviceSessionId();
        }).catch((error) => {
            console.error('Error al cargar el script de OpenPay:', error);
        });
    }, []);

    return {
        functions: {
            onSubmit,
            getCodigoPostal,
            getBuscaCorreo,
            postValidateEmail,
            postInsertCart,
            getSearchCard,
            postClientOpenPay,
            postCardOpenPay,
            completeOpenPay
        },
        states: {
            direccion,
            dataRegsitro,
            openpay
        }
    };
}

export default useFormPayHook;