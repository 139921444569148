import '../css/SectionTwo.css';
import img1 from '../../../../assets/images/Socios_01.png'
import img2 from '../../../../assets/images/Socios_02.png'
import img3 from '../../../../assets/images/Socios_03.png'
import img4 from '../../../../assets/images/Socios_04.png'
import propuesta1 from '../../../../assets/images/propuesta1.jpg';
import propuesta2 from '../../../../assets/images/propuesta2.jpg';
import propuesta3 from '../../../../assets/images/propuesta3.jpg';

const SectionTwo = ({ reference }) => {
    return (
        <>
            <div className="grids">
                <h1 className="title">PROPUESTA DE VALOR</h1>
            </div>

            <div className="grid">
                <div className="col-12 lg:col-4 d-flex flex-column justify-content-center align-items-center">
                    <div className="text-center p-3 border-round-sm">
                        <img className="w-5" src={propuesta1} alt="" />
                    </div>
                    <div className="text-center p-3 border-round-sm">
                        <p className='text01'>Compromiso con el medio ambiente <br></br>y nuestras comunidades</p>
                    </div>
                </div>

                <div className="col-12 lg:col-4 d-flex flex-column justify-content-center align-items-center">
                    <div className="text-center p-3 border-round-sm">
                        <img className="w-5" src={propuesta2} alt="" />
                    </div>
                    <div className="text-center p-3 border-round-sm">
                        <p className='text01'>La mejor calidad en tus productos</p>
                    </div>
                </div>

                <div className="col-12 lg:col-4 d-flex flex-column justify-content-center align-items-center">
                    <div className="text-center p-3 border-round-sm">
                        <img className="w-5" src={propuesta3} alt="" />
                    </div>
                    <div className="text-center p-3 border-round-sm">
                        <p className='text01'>Seguridad en tu compra y entrega</p>
                    </div>
                </div>
            </div>

            <div className="grids hidden md:block" ref={reference}>
                <h1 className="title">¿QUIÉNES SOMOS?</h1>
            </div>



            <div className="grid hidden md:flex">
                <div className="col-12 md:col-6 lg:col-2 hidden lg:block">
                    <img className="w-full p-4" src={img1} alt="" />
                </div>
                <div className="col-12 md:col-6 lg:col-2 hidden lg:block">
                    <img className="w-full p-4" src={img2} alt="" />
                </div>
                <div className="col-12 md:col-6 lg:col-2 text-center">
                    <img className="w-full p-4" src={img3} alt="" />
                </div>
                <div className="col-12 md:col-6 lg:col-2 text-center">
                    <img className="w-full p-4" src={img4} alt="" />
                </div>
                <div className="col-12 md:col-6 lg:col-4 flex justify-content-center">
                    <div className="text w-8">
                        Somos un grupo de amigxs que quiere romper con la idea que comer rico es comer caro. Ofrecemos una opción mexa donde puedes encontrar tus proteinas favoritas, cuidando el medio ambiente, ayudando a proveedores locales y donde la calidad no es un lujo, !es una promesa!.
                    </div>
                </div>
            </div>



        </>

    )

}
export default SectionTwo;