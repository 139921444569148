import React, {
  forwardRef,
  useContext,
  useRef,
  useImperativeHandle,
  useEffect,
  useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import img from '../../assets/images/LaFudería_Logotipo-04.png';
import { LayoutContext } from './context/layoutcontext';
import { useDispatch, useSelector } from 'react-redux';
//import { useSelector } from 'react-redux';
import global from '../../context/global.context';
import {
  loadElementsFromStorage,
  loadElementsToShoppingCart,
} from '../../redux/actions/shop/shopActions';
import './style.css';
import { setSectionPage } from '../../redux/actions/pageState/pageStateActions';
import { Badge } from 'primereact/badge';
import { Menubar } from 'primereact/menubar';

const AppTopbar = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const menubuttonRef = useRef(null);
  const topbarmenuRef = useRef(null);
  const topbarmenubuttonRef = useRef(null);
  const globalContext = useContext(global);
  const { sessionToken } = globalContext;
  const [carCount, setCarCount] = useState(0);
  const state = useSelector((state) => state.shopReducer);
  const dispatch = useDispatch();

  const { shoppingCart } = state;

  const fakeInvoke = () => {
    const demo = [
      {
        id: '5125',
        code: 'f230fh0g3',
        name: 'Bamboo Watch',
        description: 'Product Description',
        image: 'bamboo-watch.jpg',
        price: 65,
        category: 'Accessories',
        quantity: 24,
        inventoryStatus: 'INSTOCK',
        rating: 5,
      },
    ];
    return demo;
  };

  useEffect(() => {
    //console.log('me llamo appTopBar');
    if (sessionToken) {
      const elements = fakeInvoke();
      dispatch(loadElementsToShoppingCart(elements));
    } else {
      dispatch(loadElementsFromStorage());
    }
  }, [sessionToken, dispatch]);

  useEffect(() => {
    let contador = 0;
    for (let item of shoppingCart) {
      contador += item.quantity
    }
    setCarCount(contador);
  }, [shoppingCart])

  useImperativeHandle(ref, () => ({
    menubutton: menubuttonRef.current,
    topbarmenu: topbarmenuRef.current,
    topbarmenubutton: topbarmenubuttonRef.current,
  }));

  const relocateToShoppingCart = () => {
    navigate('/shopping-cart');
  };

  const changeSectionPage = (section) => {
    let url = window.location.href;
    if (url.includes('landing-page')) {
      dispatch(setSectionPage(section));
    } else {
      navigate('/')
    }

  }
  const endNavbar = (
    <div className="flex align-items-center gap-2">
      <Button className="topbar-button" icon="pi pi-search" rounded text aria-label="Filter" size="large" />
      <Button className="topbar-button" icon="pi pi-shopping-cart" rounded text aria-label="Filter" size="large" onClick={relocateToShoppingCart} />
      {carCount ? <Badge value={carCount} size='normal'></Badge> : null}
      <Button className="topbar-button" icon="pi pi-user" rounded text aria-label="Filter" size="large" />
    </div>
  );

  const menuItems = [
    {
      template: <Button className="topbar-text" label="NOSOTROS" text severity="secondary" onClick={() => { changeSectionPage('TWO'); }} size="large" />,

    },
    {
      template: <Button className="topbar-text" label="PRODUCTOS" text severity="secondary" onClick={() => { navigate('/products') }} size="large" />,

    },
    {
      template: <Button className="topbar-text" label="RECETAS" text severity="secondary" onClick={() => { changeSectionPage('FIVE') }} size="large" />,
    },
    {
      template: <Button className="topbar-text" label="CONTACTO" text severity="secondary" onClick={() => { navigate('/contact') }} size="large" />,
    },
    {
      template: endNavbar,
      command: () => { relocateToShoppingCart() }
    }
  ];

  const startNavbar = <Link to="/" className="layout-topbar-logo"><img src={img} alt="header-img" /></Link>;



  return (
    <div className="card-container">
      <Menubar className='topbar-menu' model={menuItems} start={startNavbar} />
    </div>
    // <div className="layout-topbar">
    //   <Tooltip target="#button" />

    //   <Link to="/" className="layout-topbar-logo">
    //     <img src={img} alt="header-img" />
    //   </Link>

    //   <button
    //     ref={menubuttonRef}
    //     type="button"
    //     className="p-link layout-menu-button layout-topbar-button"
    //     onClick={onMenuToggle}
    //     style={{ display: 'none' }}
    //   >
    //     <i className="pi pi-bars" />
    //   </button>

    //   <button
    //     ref={topbarmenubuttonRef}
    //     type="button"
    //     className="p-link layout-topbar-menu-button layout-topbar-button"
    //     onClick={showProfileSidebar}
    //   >
    //     <i className="pi pi-bars" />
    //   </button>

    //   <div
    //     ref={topbarmenuRef}
    //     className={classNames('layout-topbar-menu', {
    //       'layout-topbar-menu-mobile-active': layoutState.profileSidebarVisible,
    //     })}
    //   >
    //     <Button className="topbar-text" label="NOSOTROS" text severity="secondary" onClick={() => { changeSectionPage('TWO') }} size="large" />
    //     <Button className="topbar-text" label="PRODUCTOS" text severity="secondary" onClick={() => { navigate('/products') }} size="large" />
    //     <Button className="topbar-text" label="RECETAS" text severity="secondary" onClick={() => { changeSectionPage('FIVE') }} size="large" />
    //     <Button className="topbar-text" label="CONTACTO" text severity="secondary" onClick={() => { changeSectionPage('SIX') }} size="large" />

    //     <Button className="topbar-button" icon="pi pi-search" rounded text aria-label="Filter" size="large" />
    //     <Button className="topbar-button" icon="pi  pi-heart" rounded text aria-label="Filter" size="large" />

    //     <Button className="topbar-button" icon="pi pi-shopping-cart" rounded text aria-label="Filter" size="large" onClick={relocateToShoppingCart} />
    //     {carCount?<Badge value={carCount} size='normal'></Badge>:null}
    //     <Button className="topbar-button" icon="pi pi-user" rounded text aria-label="Filter" size="large" />


    //   </div>
    // </div>
  );
});

export default AppTopbar;
