import { TYPE_SHOP } from '../../types/shop/shopTypes';

const initialState = {
  shoppingCart: [],
  purchaseCart: [],
};

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE_SHOP.ADD_TO_SHOPPING_CART: {
      //console.log('elemento')
      return {
        ...state,
        shoppingCart: addElement(action.payload, state),
      };
    }
    case TYPE_SHOP.REMOVE_TO_SHOPPING_CART: {
      localStorage.setItem('purchaseCart', JSON.stringify(action.payload));
      return {
        ...state,
        ...alterCart(action.payload, state, false),
      };
    }
    case TYPE_SHOP.ADD_ELEMENT_TO_CART: {
      localStorage.setItem('purchaseCart', JSON.stringify(action.payload));
      return {
        ...state,
        ...alterCart(action.payload, state, true),
      };
    }
    case TYPE_SHOP.DELETE_ELEMENT_FROM_CART: {

      return {
        ...state,
        ...deleteElement(action.payload, state),
      };
    }
    case TYPE_SHOP.RELOAD_SHOPPING_CART: {
      return {
        ...state,
        shoppingCart: action.payload,
      };
    }
    case TYPE_SHOP.MAKE_CART_PURCHASE: {
      localStorage.setItem('purchaseCart', JSON.stringify(action.payload));
      return {
        ...state,
        purchaseCart: action.payload,
      };

    }
    case TYPE_SHOP.CLEAR_CART: {
      return {
        ...state,
        shoppingCart: [],
        purchaseCart: [],
      };
    }
    default:
      return state;
  }
};


/**
 * funtion add any element to the shopping cart
 * if no exist in the shopping cart add the element in one quantity
 * @param {*} item 
 * @param {*} state 
 * @returns 
 */
const addElement = (item, state) => {
  let shoppingCart = [];

  const exist = state.shoppingCart.find((element) => element.id === item.id);
  console.log('verificando existencia')
  if (exist) {
    const updatedExist = { ...exist }; // Hacer una copia del objeto existente
    updatedExist.quantity++; // Actualizar la propiedad quantity en la copia
    const updatedShoppingCart = state.shoppingCart.map(item =>
      item.id === exist.id ? updatedExist : item // Reemplazar el objeto existente con la copia actualizada
    );
    shoppingCart = [...updatedShoppingCart] // Devolver un nuevo estado con el carrito actualizado
  } else {
    //item.quantity = 1;
    const newCart = [...state.shoppingCart, item];
    console.log('No existo')
    console.log(newCart);
    shoppingCart = newCart;
  }

  shoppingCart.length > 0 && localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart));
  return shoppingCart;
}



/**
 * funtion add any element to the shopping cart and purchase cart
 * @param {*} item 
 * @param {*} state 
 * @returns 
 */
const alterCart = (item, state, isAdd) => {
  let shoppingCart = state.shoppingCart;
  let purchaseCart = state.purchaseCart;
  const existShoppingCart = state.shoppingCart.find((element) => element.id === item.productId);
  const existPurchaseCart = state.purchaseCart.find((element) => element.productId === item.productId);
  ////console.log("exist",existPurchaseCart);
  if (existShoppingCart && existPurchaseCart) {
    const updatedExist = { ...existShoppingCart }; // Hacer una copia del objeto existente
    const updatedExistPurchase = { ...existPurchaseCart }; // Hacer una copia del objeto existente
    if (isAdd) {
      updatedExist.quantity++
      updatedExistPurchase.quantity++
    } else if (existShoppingCart.quantity > 1) {
      updatedExist.quantity--
      updatedExistPurchase.quantity--
    }
    const updatedShoppingCart = state.shoppingCart.map(item =>
      item.id === existShoppingCart.id ? updatedExist : item // Reemplazar el objeto existente con la copia actualizada
    );
    const updatedPurchaseCart = state.purchaseCart.map(item =>
      item.productId === existPurchaseCart.productId ? updatedExistPurchase : item // Reemplazar el objeto existente con la copia actualizada
    );
    //console.log('update',updatedPurchaseCart);
    shoppingCart = [...updatedShoppingCart]
    purchaseCart = [...updatedPurchaseCart]
  }
  localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart));
  //console.log('shopping',shoppingCart);
  //console.log('purchase',purchaseCart);
  return {
    shoppingCart,
    purchaseCart
  };
}

const deleteElement = (item, state) => {
  let shoppingCart = state.shoppingCart.filter((element) => element.id !== item.productId);
  let purchaseCart = state.purchaseCart.filter((element) => element.id !== item.productId);
  //7console.log("shopping car",state.shoppingCart[0]);
  //console.log("item",item);
  localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart));
  return {
    shoppingCart,
    purchaseCart
  };
}

export default shopReducer;
